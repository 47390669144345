<template>
    <div class="category idea">
      <div class="module-title">
        <img
          :src="imgUrl"
        />
        <p>{{name}}</p>
        
        <span v-if="id" @click="toList('LessonTuoyu', id)"
          >更多
          <van-icon name="arrow" color="#144094" size="16" />
        </span>
      </div>
      <div class="tab">
        <p
          v-for="(item, index) of list"
          :class="{ active: index == vipIndex }"
          @click="vipIndex = index"
          :key="item.id"
        >
          {{ item.name }}
        </p>
      </div>
      <div class="course-item" style="flex-wrap: wrap">
        <div
          class="course-card"
          style="width: calc((100% - 48px) / 3)"
          v-for="card of list[vipIndex].list.map(item=>item.list[0]).flat().slice(0,3)"
          :key="card.id"
          @click="jumpLesson(card)"
        >
          <div
            class="course-card-cover"
            :style="{ height: ((width - 48) / 3 * 9) / 16 + 'px' }"
          >
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="card.is_free == 2" />
              <img :src="$vip" v-else-if="card.is_vip_flat == 1" />
              <img :src="$noVip" v-else />
            </div>
            <van-image
              class="course-cover"
              width="100%"
              :error-icon="$placeImg"
              :loading-icon="$placeImg"
              :src="card.cover_mobile"
            >
              <template v-slot:error>
                <van-image
                  style="width: 100%; height: 100%"
                  fit="cover"
                  :src="$placeImg"
                ></van-image>
              </template>
            </van-image>
          </div>
          <div class="course-card-detail">
              <p >{{ card.name }}</p>
              <p class="des ellipsis">{{ card.description }}</p>
            <!-- <p class="price">
              ￥<b>{{ card.price }}</b
              ><span>￥{{ card.express_price }}</span>
            </p> -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "index",
  props:['list', 'name', 'imgUrl','id'],
  data() {
    return {
      vipIndex: 0,
      width: 1200
    };
  },
  async mounted() {
    
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
         
  },
  methods: {
    tojiazhang() {
    let url = 'https://jz.tuoyufuwu.org.cn/edu/index.html#/jiazhang/index'
    if(this.$store.state.user){
      url += '?mobile='+this.$store.state.user.mobile;
    }
    
    window.open(url);
  },
  // 去列表
  toList(url, id, index) {
    
    let myUrl = `/${url}?id=${id}`;
    console.log(url);
    console.log(id);
    if (index) myUrl = myUrl + "&index=" + index;
    this.$until.toPageOpen(myUrl);
  },
    jumpLesson(item) {
      console.log(item)
      let url = `http://jz.tuoyufuwu.org.cn/edu/index.html#/jiazhang/detail2?id=${item.id}`
      if(this.$store.state.user){
      url += '&mobile='+this.$store.state.user.mobile;
    }
      window.open(url);
    },
    
  },
  beforeRouteUpdate(to, from, next) {
   
  },
};
</script>

<style lang="scss" scoped>

  

  .module-title {
    font-size: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 41px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-weight: bold;
      font-family: "SourceHanSans";
    }
    span {
      color: #144094;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .course-card2 {
    margin-top: 87px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 100vw;
      min-width: 1200px;
      height: 100%;
      background: #fff;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
    }
    .module-title {
      padding-top: 42px;
      padding-bottom: 11px;
    }
    @keyframes toScale {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.2);
      }
    }

    .course-cover {
      flex-shrink: 0;
      ::v-deep img {
        cursor: pointer;
        &:hover {
          animation: toScale 0.3s;
          transform: scale(1.2);
        }
      }
    }
    .course-card2-item {
      padding: 29px 0;
      width: 100%;
      display: flex;
      &:not(:last-of-type) {
        background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/line.jpg")
          bottom no-repeat;
      }
    }
    .course-card-cover {
      width: 560px;
      height: 315px;
      border-radius: 10px;
      overflow: hidden;
      flex-shrink: 0;
    }
    
  }

  .course-card-detail {
      flex: 1;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      line-height: 24px;
      margin-bottom: 4px;
      font-size: 16px;
      p:first-of-type{
        font-weight: bold;
      }
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 24px;
        color: #333333;
        font-size: 16px;
      }
      .des {
        font-size: 14px;
        margin-top: 5px;
        color: #7f7f7f;
      }
      .lessonTm {
        font-size: 12px;
        color: #006bb3;
        font-weight: bold;
      }
      .detail {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #666666;
        line-height: 26px;
      }
      .lessonTime {
        flex: 1;
        font-size: 16px;
        color: #006bb3;
      }
      > div {
        display: flex;
        justify-content: space-between;
        color: #7f7f7f;
        .price {
          color: #ff6c00;
          font-size: 12px;
          b {
            font-size: 24px;
          }
        }
      }
    }
  .course-item {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .levelTitle {
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      p {
        cursor: pointer;
        font-size: 16px;
        color: #0080d6;
      }
    }
    .course-card {
      margin-right: 24px;
      margin-bottom: 24px;
      height: 333px;
      &:nth-of-type(3n + 3) {
        margin-right: 0;
      }
    }
    
  }
  .course-list {
    padding-top: 10px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      border-radius: 8px;
      margin-right: 20px;
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }

   
    .card-btn {
      cursor: pointer;
      border-radius: 6px;
      line-height: 28px;
      border-radius: 6px;
      color: #0080d6;
      background: #f7f7f7;
      padding: 0 20px;
      font-size: 16px;
      flex: 0 0 auto;
      text-align: center;
      margin-top: 4px;

      &:hover {
        color: #fff;
        background: #0080d6;
      }
    }
  }


  
  .category {
    .tab {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        height: 40px;
        flex: 1;
        text-align: center;
        line-height: 40px;
        margin-right: 20px;
        display: inline-block;
        font-size: 14px;
        border-radius: 10px;
        cursor: pointer;
        color: #454545;
        background: #f2f2f2;
        &:hover {
          background: #2e66d0;
          color: #fff;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .active {
        background: #2e66d0;
        color: #fff;
      }
    }
  }

</style>

<style lang="scss">
.index {
  .van-skeleton__row {
    height: 26px;
    margin-top: 20px;
  }
}
</style>
<style scoped lang="scss">
.index {
  width: 1200px !important;
  margin: 0 auto;
}
</style>
