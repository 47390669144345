<template>
  <div class="index" v-loading="pageLoading" ref="index">
    <img src="../assets/images/wjw/banner.png" style="margin-top:10px"/>
    <div class="level" v-if="!pageLoading">
      <!-- 卫生保健工作 -->
      <list
            spaceBetween="25"
            levelNum="4"
            :list="category[0].list[0].list[0].slice(0,2)"
            detailUrl="/playerLesson"
            :name="category[0].name"
            contentHeight="66"
            :imgUrl="img1"
          ></list>
          <!-- 保育照顾 -->
          <list
            spaceBetween="25"
            levelNum="4"
            :list="category[1].list[0].list[0].slice(0,2)"
            detailUrl="/playerLesson"
            :name="category[1].name"
            contentHeight="66"
            :imgUrl="img2"
          ></list>
    </div>
    <div class="level" v-if="!pageLoading">
      <!-- 习惯养成 -->
      <list
      
            spaceBetween="25"
            levelNum="4"
            :list="category[2].list[0].list[0].slice(0,2)"
            detailUrl="/playerLesson"
            :name="category[2].name"
            contentHeight="66"
            :imgUrl="img3"
          ></list>
          <!-- 安全照护 -->
          <list
            spaceBetween="25"
            levelNum="4"
            :list="category[4].list[0].list[0].slice(0,2)"
            detailUrl="/playerLesson"
            :name="category[4].name"
            contentHeight="66"
            :imgUrl="img5"
          ></list>
    </div>
    
    <!-- 早期发展 -->
    <list
      v-if="!pageLoading"
      style="padding-bottom: 50px"
      spaceBetween="25"
      levelNum="4"
      :list="category[3].list[0].list[0]"
      detailUrl="/playerLesson"
      :name="category[3].name"
      contentHeight="66"
      :imgUrl="img4"
    ></list>
    <!-- v-if="category.length && category[4].list[0].list[0].length" -->

    
  </div>
</template>

<script>
document.ondragstart = function () {
  return false;
};
import list from "@/components/IndexListCommon";
import img1 from "@/assets/images/wjw/wjw1.png";
import img2 from "@/assets/images/wjw/wjw2.png";
import img3 from "@/assets/images/wjw/wjw3.png";
import img4 from "@/assets/images/wjw/wjw4.png";
import img5 from "@/assets/images/wjw/wjw5.png";
export default {
  name: "index",
  components: { list },
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      pageLoading: true,
      category: [],
      width: 1200,
    };
  },
  async mounted() {
    await this.getData();
  },

  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
  },
  watch: {
    plat() {
      this.getData();
    },
  },
  methods: {
    async getData() {
      this.pageLoading = true;
      // this.$request.tuoyuCategory2({ flat_id: this.plat }).then((res) => {
      //   this.cateList = res.data;
      // });
      let res = await this.$api({
        method: "get",
        url: "/api/zhong/index",
      });
      this.pageLoading = false;
      // console.log(res)
      if (res.code == 0) {
        res.data.category_list.forEach(item=>{
          let myArr = new Array(4).fill(null)
          if(item.list[0].list[0].length < 4){
            myArr = myArr.splice(0,item.list[0].list[0].length)
            item.list[0].list[0].push(...myArr)
          }
        })
        this.category = res.data.category_list
        
      } else {
        this.$toast(res.msg);
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.$store.commit("SET_PLAT", to.params.plat_type);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #dedee4;
  border-radius: 5px;
  margin: 5px 0;
}
body {
  background: #fafafa;
}
</style>
<style lang="scss" scoped>

</style>
<style scoped lang="scss">
.index {
  width: 1200px !important;
  margin: 0 auto;
  .level{
    display: flex;
  }
}
</style>
